import { render, staticRenderFns } from "./SubmissionList.vue?vue&type=template&id=4b8e205c&scoped=true&lang=pug&"
import script from "./SubmissionList.vue?vue&type=script&lang=js&"
export * from "./SubmissionList.vue?vue&type=script&lang=js&"
import style0 from "@platyplus/humanitarian-icons/dist/icons.css?vue&type=style&index=0&lang=css&"
import style1 from "./SubmissionList.vue?vue&type=style&index=1&id=4b8e205c&lang=scss&scoped=true&"
import style2 from "./SubmissionList.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b8e205c",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBanner,QBreadcrumbs,QIcon,QBreadcrumbsEl,QBtn,QTooltip,QPopupEdit,QInput,QImg,QSpinner,QTimeline,QTimelineEntry,QDialog,QCard,QVideo,QCardSection});
